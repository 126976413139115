import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  useAllPrismicDocumentsByType,
  usePrismicDocumentByUID,
} from "@prismicio/react";
import Loader from "./Components/Common/Loader/Loader";

const Home = lazy(() => import("./Pages/Landing/Home"));
const Diggit = lazy(() => import("./Pages/Projects/Projects"));
const renderLoader = () => <Loader />;

function App() {
  const [document] = useAllPrismicDocumentsByType("projects");

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={renderLoader()}>
                <Home documents={document} />
              </Suspense>
            }
          />

          <Route
            path="/:uid"
            element={
              <Suspense fallback={renderLoader()}>
                <Diggit />
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
