import React from "react";
import logo from "../../../assets/Images/logo_temp 1.svg";

function Loader() {
  return (
    <div className="loader-section">
      <img className="logo" src={logo} alt="logo" />
    </div>
  );
}

export default Loader;
